<template>
  <div class="form-group" :class="{'row': isInline}">
    <label v-if="title" :for="name" class="col-form-label" :class="{
      'col-lg-4 col-md-4 col-sm-12': isInline,
      'col-form-label-sm': size.toString() === 'sm',
      'col-form-label-lg': size.toString() === 'lg',
    }">{{ title }}<span class="text-danger" v-if="required">*</span>
      <info-tooltip
          :helper-text="helperText"
          v-if="helperText"
      ></info-tooltip>
    </label>
    <input type="file"  :name="name" :disabled="false"  ref="file" class="d-none" @change="onChangeFile"
            :class="{'col-lg-8 col-md-8 col-sm-12': isInline,
                    'form-control-sm': size.toString() === 'sm',
                    'form-control-lg': size.toString() === 'lg',
                   }"
            :accept="accept">
    <button @click="$refs.file.click()" type="reset" class="btn btn-success"> {{$t("general.new_photo")}}</button>
  </div>
</template>
<script>
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
export default {
  name: "FileInputChange",
  props: {
    name: {
      default: 'name',
    },
    model: {},
    title: {},
    error: {},
    hint: {},
    accept: {
      default: "image/*",
    },
    isInline: {
      required: false,
      default:true,
    },
    placeholder: {},
    required: {
      default: true
    },
    helperText: {
      default: null,
      required: false,
      type: String,
    },
    size: {
      default: 'sm',
      validator: function validator(l) {
        return ['xs', 'sm', 'md', 'lg'].indexOf(l) > -1;
      }
    },
  },
  methods: {
    onChangeFile(){
      this.modelProxy = this.$refs.file.files[0];
    },
  },
  components: {
    InfoTooltip
  },
  computed: {
    modelProxy: {
      get() {
        return this.model
      },
      set(value) {
        this.$emit('update:model', value)
        let mimeType = value['type'];
        let blob = URL.createObjectURL(value);
        this.$emit('fileChange', {
          "id": "preview-" + this.name,
          "file": value,
          "blob": blob,
          "type": mimeType
        });
      }
    }
  },
}
</script>
<style scoped>
</style>