<template>
  <div class="row create-general-original-sample-master">

    <b-modal ref="select-user-modal" class="create-original-sample-master" id="select-user-modal" hide-header hide-header-close size="md" scrollable>
      <select-sample-user-modal
          :users="sampleUsers"
          :model.sync="selectedUserProxy"
          @onCloseModal="onCloseModal"
          @onConfirm="onCreateSample"
      ></select-sample-user-modal>

      <template #modal-footer>
        <div class="w-100">
          <div class="d-flex justify-content-center mt-3">
            <button
                type="button"
                @click="onCloseModal"
                class="btn btn-outline-warning font-weight-bold cursor-pointer ml-4 text-uppercase rounded-full ">
              <span class="font-weight-boldest" style="font-weight: bolder;">{{ $t('general.cancel') }}</span>
            </button>

            <button
                type="button"
                @click="onCreateSample"
                :disabled="selectedUserProxy == null"
                class="btn btn-outline-primary font-weight-bold cursor-pointer ml-4 text-uppercase rounded-full ">
              <span class="font-weight-boldest" style="font-weight: bolder;">{{ $t('general.save') }}</span>
            </button>
          </div>
        </div>
      </template>
    </b-modal>
    <modal
        name="update-sample-image-modal"
        :adaptive="true"
        height="auto"
        width="750"
    >
      <div class="card card-custom">
        <div class="card-header py-7">
          <h3>{{ $t("editSample.please_select_image_to_upload") }}</h3>
        </div>
        <div class="card-body card-body-scroll">
          <div class="w-100 d-flex justify-content-center mb-3">
            <img id="image_holder" style="max-height: 200px; width: auto;">
          </div>

          <button class="btn btn-success" @click="openUploadDocumentModal">{{$t('general.new_photo')}}</button>

        </div>
        <div class="card-footer d-flex justify-content-end">
          <button @click="closeModalByName('update-sample-image-modal')"
                  type="button"
                  class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">{{ $t('editSample.close') }}
          </button>
          <button @click="addContent(3, 'update-sample-image-modal', 1)"
                  type="button"
                  class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" height="auto" name="upload-new-image" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll">
          <photo-recorder
              :model.sync="currentContent"></photo-recorder>
          <file-input
              ref="imageFileInput"
              :model.sync="currentContent"
              :title="$t('editSample.image')"
              accept="image/*"
              name="content-image">
            <template v-slot:inputDesign>
              <div class="w-100 d-flex justify-content-center" @click="$refs.imageFileInput.openFileInput()">
                <span v-html="getIconByKey('icons.meeting.select-from-file', {
                      class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </template>
          </file-input>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3 btn-pill"
                  type="button"
                  @click="cancelDocumentUpload">
            {{ $t('editSample.close') }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer btn-pill"
                  type="button"
                  @click="closeUploadDocumentModal">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>
    <CoolLightBox
        :items="imageLinks"
        :index="imageIndex"
        @close="imageIndex = null">
    </CoolLightBox>

    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="samples">
        <template v-slot:title>
          <span>{{ $t('general.original_sample') }}</span>
        </template>
        <template v-slot:preview>
          <div class="w-100 ">
            <b-row>
              <b-col md="6" sm="6">
                <div class="form-group">
                  <label class="typo__label">{{ $t('general.customer') }}</label>
                  <multiselect
                      v-model="selectedCompanyProxy"
                      :placeholder="$t('createOriginalSample.please_select_customer_to_create_collection_product')"
                      label="name"
                      :options="companies"
                      :option-height="104"
                      track-by="name"
                      :show-labels="false">
                    <template slot="singleLabel" slot-scope="props">
                      <template v-if="props.option">
                        <img
                            class="option__image company-option-preview-image"
                            :src="props.option.avatar">
                        <span class="option__desc ml-4 d-inline-block">
                  <span class="option__title">
                  {{ props.option.name }}
                  </span>
                </span>
                      </template>
                      <template v-else>
                        <h6>
                    <span v-html="getIconByKey('icons.meeting.company-participant', {
                        class: 'w-25px h-25px object-cover mr-2 d-inline-block'
                  })"></span>
                          {{ $t('general.please_select_company_for_sample') }}
                        </h6>
                      </template>
                    </template>
                    <template slot="option" slot-scope="props">
                      <img
                          class="option__image company-option-preview-image"
                          :src="props.option.avatar">
                      <span class="option__desc ml-4 d-inline-block">
                  <span class="option__title">
                  {{ props.option.name }}
                  </span>
                </span>
                    </template>
                  </multiselect>
                </div>
                <div class="d-flex" style="align-items: center;" v-if="!selectedCompanyValidation">
                  <button-with-icon
                      v-if="isUserGranted('Company', ['create', 'update'], false)"
                      @onClick="$router.push({name: 'general.company.create', params: {
                      parent: 'sample',
                      parentName: 'general.original_sample',
                      parentRouteName: 'sample.create.create',
                    }})"
                      icon-name="icons.meeting.add-new-client"
                      class="navy-blue-border-1px"
                      size="md"
                      :text="$t('createOriginalSample.create_new_customer')"
                  ></button-with-icon>
                </div>
              </b-col>
              <b-col md="6" sm="6">
                <div class="form-group" v-if="selectedCompanyValidation">
                  <label class="typo__label">{{ $t('general.product_type') }}</label>
                  <multiselect
                      v-model="selectedProductTypeProxy"
                      :placeholder="$t('createOriginalSample.please_select_product_type')"
                      label="title"
                      :options="productTypes"
                      :option-height="104"
                      track-by="name"
                      :show-labels="false">
                    <template slot="singleLabel" slot-scope="props">
                      <template v-if="props.option">
                  <span class="option__desc d-inline-block">
                    <span class="option__title">
                      <span v-if="Number(props.option.id) === 1"
                            v-html="getIconByKey('icons.sample.k-product', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 2"
                            v-html="getIconByKey('icons.sample.fabric', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 3"
                            v-html="getIconByKey('icons.sample.accessory', {})"
                      ></span>
                      {{ props.option.name }}
                    </span>
                </span>
                      </template>
                      <template v-else>
                        <h6>{{ $t('general.please_select_company_for_sample') }}</h6>
                      </template>

                    </template>
                    <template slot="option" slot-scope="props">
                <span class="option__desc d-inline-block">
                    <span class="option__title">
                      <span v-if="Number(props.option.id) === 1"
                            v-html="getIconByKey('icons.sample.k-product', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 2"
                            v-html="getIconByKey('icons.sample.fabric', {})"
                      ></span>
                      <span v-if="Number(props.option.id) === 3"
                            v-html="getIconByKey('icons.sample.accessory', {})"
                      ></span>
                      {{ props.option.name }}
                    </span>
                </span>
                    </template>
                  </multiselect>
                </div>
              </b-col>
              <b-col md="12" sm="12" class="text-center" v-if="selectedProductTypeValidation">
                <div class="d-inline-flex">
                  <button-with-icon
                      @onClick="sampleEntryType = 1"
                      :text="$t('createOriginalSample.enter_product_name')"
                      icon-name="icons.sample.edit"
                      :disabled="sampleEntryType !== 1"
                      :active="true"
                  ></button-with-icon>
                </div>
                <strong class="d-inline-block mx-5"> {{ $t('createOriginalSample.or') }} </strong>
                <div class="d-inline-flex">
                  <button-with-icon
                      @onClick="sampleEntryType = 2"
                      :text="$t('createOriginalSample.scan_qr_code')"
                      icon-name="icons.sample.scan-qr-code"
                      :disabled="sampleEntryType !== 2"
                      :active="true"
                  ></button-with-icon>
                </div>
                <img
                    v-if="sampleEntryTypeValidation"
                    @click="sampleImageProxy != null ? openSelectImageModal() : closeModalByName('update-sample-image-modal')"
                    :src="mediaBase + '/icon/no-photos.png'" :class="{'cursor-pointer': sampleImageProxy != null }" alt=""
                    id="sample-image-holder" class="w-60px h-60px object-fill d-none float-right border-radius-left-top-10px border-radius-left-top-10px border-radius-left-bottom-10px border-radius-right-bottom-10px" />
              </b-col>
            </b-row>
            <template v-if="Number(sampleEntryType) === 1">
              <b-row>
                <b-col md="12">
                  <div class="d-flex text-center justify-center justify-content-center">
                    <text-input
                        :title="$t('createOriginalSample.product_name')"
                        :placeholder="$t('createOriginalSample.please_enter_product_name')"
                        :model.sync="productNameProxy"
                        :is-valid="productNameValidation ? true : null"
                        :is-inline="false"
                    ></text-input>
                  </div>
                </b-col>
                <b-col md="12">
                  <div class="justify-center justify-content-center text-center d-flex">
                    <h6 class="text-success">*
                      {{ $t('editSample.please_select_image_first') }}
                      <info-tooltip
                          :helper-text="$t('editSample.please_select_image_for_sample_beforehand')"
                      ></info-tooltip>
                      <button type="button"
                              @click="openSelectImageModal"
                              class="btn btn-outline-success btn-sm">
                        {{ $t("editSample.select") }}
                      </button>
                    </h6>
                  </div>
                </b-col>
                <b-col md="12" class="mt-3">
                  <div class="d-flex justify-content-center items-center">
                    <template v-if="productNameValidation && productContents !== null && productContents.length !== 0 ">
                      <button-with-icon
                          @onClick="openSelectUserModal"
                          icon-name="icons.sample.sample-responsible-person"
                          class="green-border-1px text-primary"
                          size="lg"
                          :text="$t('createOriginalSample.select_responsible_person_for_sample')"
                      ></button-with-icon>
                    </template>
                  </div>
                  <div class="d-flex justify-content-center items-center" v-if="selectedUser">
                      <span class="text-xl font-weight-bold text-primary d-inline-block py-5">{{ convertArrayToObjectByKey(sampleUsers, 'id')[selectedUser].name }}</span>
                    </div>
                </b-col>
              </b-row>

              <template v-if="productNameValidation && productContents !== null && productContents.length !== 0 ">
                <b-row class="my-5">
                  <template v-for="content in productContents" v-if="productContents !== null && productContents.length !== 0 ">
                    <b-col md="6" sm="12" lg="4" v-if="Number(content.content_type_id) === 1" class="mb-3">
                      <meeting-text-holder-card @onClickDelete="onDeleteContent" :is-deletable="true" :is-sharable="false"
                                                :content="content"></meeting-text-holder-card>
                    </b-col>

                    <b-col md="6" sm="12" lg="4" v-if="Number(content.content_type_id) === 2" class="mb-3">
                      <meeting-video-holder-card @onClickDelete="onDeleteContent" :is-deletable="true" :is-sharable="false"
                                                 :content="content"></meeting-video-holder-card>
                    </b-col>

                    <b-col md="6" sm="12" lg="4" v-if="Number(content.content_type_id) === 3" class="mb-3">
                      <meeting-image-holder-card @onClickDelete="onDeleteContent" :is-deletable="true" :is-sharable="false"
                                                 :content="content"></meeting-image-holder-card>
                    </b-col>
                    <b-col md="6" sm="12" lg="4" v-if="Number(content.content_type_id) === 4" class="mb-3">
                      <meeting-audio-player-holder-card @onClickDelete="onDeleteContent"
                                                        :is-sharable="false"
                                                        :is-deletable="true"
                                                        :content="content">
                      </meeting-audio-player-holder-card>
                    </b-col>
                  </template>
                </b-row>

                <div class="row d-flex justify-content-center my-5">
                  <modal
                      name="add-product-image-modal"
                      :adaptive="true"
                      height="auto"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-body card-body-scroll">
                        <photo-recorder :model.sync="currentContent"></photo-recorder>
                        <file-input
                            ref="imageFileInput"
                            name="content-image"
                            :model.sync="currentContent"
                            :title="$t('editSample.image')"
                            accept="image/*"
                            @fileChange="onNewImageSelected"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.imageFileInput.openFileInput()"
                            >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button @click="closeModalByName('add-product-image-modal')"
                                type="button"
                                class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                          {{ $t('editSample.close') }}
                        </button>
                        <button @click="addContent(3, 'add-product-image-modal')"
                                :disabled="is_submitting"
                                type="button"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span v-html="getIconByKey('icons.meeting.add-image', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })"  @click="openAddProductImageModal">
                </span>
                  <modal
                      name="add-product-video-modal"
                      :adaptive="true"
                      height="auto"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-header py-7">
                        <h3>{{ $t("editSample.please_select_video_to_upload") }}</h3>
                      </div>
                      <div class="card-body card-body-scroll ">
                        <video-recorder :model.sync="currentContent"></video-recorder>
                        <file-input
                            ref="videoFileInput"
                            name="content-video"
                            :model.sync="currentContent"
                            :title="$t('editSample.video')"
                            accept="video/*"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.videoFileInput.openFileInput()"
                            >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button @click="closeModalByName('add-product-video-modal')"
                                type="button"
                                :disabled="is_submitting"
                                class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                          {{ $t('editSample.close') }}
                        </button>
                        <button type="button"
                                ref="submitVideo"
                                @click="addContent(2, 'add-product-video-modal')"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span v-html="getIconByKey('icons.sample.add-video', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })"  @click="openAddProductVideoModal">
                </span>
                  <modal
                      name="add-product-audio-modal"
                      :adaptive="true"
                      height="auto"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-body card-body-scroll">
                        <audio-recorder :model.sync="currentContent" class="mx-auto mb-3"></audio-recorder>
                        <file-input
                            ref="audioFileInput"
                            name="content-image"
                            :model.sync="currentContent"
                            :title="$t('editSample.image')"
                            accept="audio/*"
                            @fileChange="onNewImageSelected"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.audioFileInput.openFileInput()"
                            >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button @click="closeModalByName('add-product-audio-modal')"
                                type="button"
                                class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                          {{ $t('editSample.close') }}
                        </button>
                        <button type="button"
                                :disabled="is_submitting"
                                @click="addContent(4, 'add-product-audio-modal')"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span v-html="getIconByKey('icons.sample.add-audio', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })"  @click="openAddProductAudioModal">
                </span>
                  <modal
                      name="add-product-text-modal"
                      :adaptive="true"
                      height="auto"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-body card-body-scroll">
                        <textarea-input
                            :title="$t('editSample.text')"
                            :model.sync="currentContent"
                        ></textarea-input>

                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button @click="closeModalByName('add-product-text-modal')"
                                type="button"
                                class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3">
                          {{ $t('editSample.close') }}
                        </button>
                        <button @click="addContent(1, 'add-product-text-modal')"
                                type="button"
                                class="btn btn-primary font-weight-bold cursor-pointer">{{ $t('editSample.save') }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span v-html="getIconByKey('icons.meeting.add-note', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })"  @click="openAddProductTextModal">
                </span>
                </div>
                <div class="my-4 py-4 d-flex justify-content-center">
                  <button type="button"
                          :disabled="is_submitting"
                          v-if="selectedUserValidation"
                          @click="submitSample(false)"
                          class="btn btn-sm btn-outline-success btn-pill mr-4">
                    {{ $t('createOriginalSample.save_and_create_instruction') }}
                  </button>
                  <button type="button"
                          :disabled="is_submitting"
                          v-if="!selectedUserValidation"
                          @click="submitSample(false)"
                          class="btn btn-sm btn-outline-success btn-pill">
                    {{ $t('createOriginalSample.save_and_continue') }}
                  </button>
                </div>
              </template>
            </template>
          </div>

        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import VideoRecorder from "@/assets/components/recorder/VideoRecorder";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import {
  ERROR,
  SUCCESS,
  LOADING,
  GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE,
  COMPANIES,
  PRODUCT_TYPES,
  SELECTED_PRODUCT_TYPE,
  SELECTED_COMPANY,
  SAMPLE_USERS,
  SELECTED_USER,
  SET_SELECTED_USER,
  SET_SELECTED_PRODUCT_TYPE,
  SET_SELECTED_COMPANY,
  PRODUCT_NAME,
  SET_PRODUCT_NAME,
  SAMPLE_IMAGE,
  SET_SAMPLE_IMAGE,
  PRODUCT_CONTENTS,
  APPEND_PRODUCT_CONTENTS,
  SET_PRODUCT_CONTENTS,
  DELETE_PRODUCT_CONTENT_BY_ID, CREATE_ORIGINAL_SAMPLE,
} from '@/core/services/store/sample/original-sample/create.original.sample.module';
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import TextInput from "@/assets/components/inputs/TextInput";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import SelectSampleUserModal from "@/view/pages/samples/layout/meeting-detail/SelectSampleUserModal";
import MeetingImageHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingImageHolderCard";
import MeetingVideoHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingVideoHolderCard";
import MeetingAudioPlayerHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingAudioPlayerHolderCard";
import MeetingTextHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingTextHolderCard";
import $ from "jquery";
import FileInput from "@/assets/components/inputs/FileInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import FileInputChange from "@/assets/components/inputs/FileInputChange";

import {v4 as uuidv4} from 'uuid'

import Vue from 'vue'
import AudioRecorder from "@/assets/components/recorder/AudioRecorder";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";
Vue.use(AudioRecorder)
export default {
  name: "CreateOriginalSample",
  components: {
    PhotoRecorder,
    VideoRecorder,
    TextareaInput,
    FileInput,
    InfoTooltip,
    TextInput,
    SelectInput,
    MultipleSelectInput,
    DashboardBox,
    SelectSampleUserModal,
    MeetingImageHolderCard,
    MeetingAudioPlayerHolderCard,
    MeetingVideoHolderCard,
    MeetingTextHolderCard,
    AudioRecorder,
    ButtonWithIcon,
    FileInputChange
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      success: SUCCESS,
      error: ERROR,
      companies: COMPANIES,
      productTypes: PRODUCT_TYPES,
      sampleImage: SAMPLE_IMAGE,
      selectedProductType: SELECTED_PRODUCT_TYPE,
      selectedCompany: SELECTED_COMPANY,
      productName: PRODUCT_NAME,
      sampleUsers: SAMPLE_USERS,
      selectedUser: SELECTED_USER,
      productContents: PRODUCT_CONTENTS,
    }),
    userInfo() {
      if (this.selectedUserProxy == null) {
        return {};
      }
      for (let i = 0; i < this.sampleUsers.length; i++) {
        if (Number(this.sampleUsers[i].id) === Number(this.selectedUserProxy)) {
          return this.sampleUsers[i];
        }
      }
    },
    productNameProxy: {
      set(value) {
        this.setProductName(value);
      },
      get() {
        return this.productName;
      }
    },
    selectedUserProxy: {
      set(value) {
        this.setSelectedUser(value);
      },
      get() {
        return this.selectedUser;
      }
    },
    selectedCompanyProxy: {
      set(value) {
        this.setSelectedCompany(value);
      },
      get() {
        return this.selectedCompany;
      }
    },
    selectedProductTypeProxy: {
      set(value) {
        this.setSelectedProductType(value);
      },
      get() {
        return this.selectedProductType;
      }
    },
    sampleImageProxy: {
      set(value) {
        this.setSampleImage(value);
      },
      get() {
        return this.sampleImage;
      }
    },
    selectedCompanyValidation() {
      return this.selectedCompanyProxy != null;
    },
    selectedProductTypeValidation() {
      return this.selectedProductType != null;
    },
    sampleEntryTypeValidation() {
      return Number(this.sampleEntryType) !== 0;
    },
    productNameValidation() {
      return !(this.productNameProxy == null || this.productNameProxy.length === 0);
    },
    sampleImageValidation() {
      return this.sampleImageProxy != null;
    },
    selectedUserValidation() {
      return this.selectedUserProxy != null;
    }
  },
  data() {
    return {
      imageLinks: [],
      imageIndex: null,
      authorizedRolesToSubmit: [1, 11],

      is_submitting: false,
      sampleEntryType: 0,
      sampleEntryTypeOptions: [
        {
          id: 1,
          name: this.$t('createOriginalSample.create_manually'),
          icon: 'fas fa-hand-point-up'
        },
        {
          id: 2,
          name: this.$t('general.scan_qr_code'),
          icon: 'fas fa-qrcode'
        }
      ],
      currentContent: null,
      videoPlayer: {},
      tempVideoBlob: null,
      tempAudioBlob: null,
    };
  },
  methods: {
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    cancelDocumentUpload() {
      this.currentContent = null;
      this.closeUploadDocumentModal();
    },
    closeUploadDocumentModal() {
      if (this.currentContent) {
        const img = document.getElementById(`image_holder`);
        if (img) {
          img.src = URL.createObjectURL(this.currentContent)
        }
      }
      this.$modal.hide('upload-new-image');
    },
    openUploadDocumentModal() {
      this.$modal.show('upload-new-image');
    },
    imageChange() {
      this.currentContent = this.$refs.file.files[0];
      let imageHolder = $('#image_holder');
      imageHolder.attr('src', URL.createObjectURL(this.currentContent));
    },
    ...mapMutations({
      setSelectedProductType: SET_SELECTED_PRODUCT_TYPE,
      setSelectedCompany: SET_SELECTED_COMPANY,
      setProductName: SET_PRODUCT_NAME,
      setSelectedUser: SET_SELECTED_USER,
      setSampleImage: SET_SAMPLE_IMAGE,
      setProductContents: SET_PRODUCT_CONTENTS,
      appendProductContents: APPEND_PRODUCT_CONTENTS,
      deleteProductContentById: DELETE_PRODUCT_CONTENT_BY_ID,
    }),
    openSelectUserModal() {
      this.$refs['select-user-modal'].show();
    },
    openSelectImageModal() {
      this.$modal.show('update-sample-image-modal');
    },
    onCloseModal() {
      this.selectedUserProxy = null;
      this.$refs['select-user-modal'].hide();
      },
    onCreateSample() {
      this.$refs['select-user-modal'].hide();
    },
    onNewImageSelected(payload) {
      let image = payload.file;
      let id = payload.id;
      let reader = new FileReader();
      reader.onload = function (e) {
        $('#' + id).attr('src', e.target.result);
        $('#' + 'sample-image-holder').attr('src', e.target.result);
        $('#sample-image-holder').removeClass('d-none');
      }
      reader.readAsDataURL(image); // convert to base64 string
    },
    onNewProductImageSelected(payload) {
      let image = payload.file;
      let reader = new FileReader();
      reader.onload = function (e) {
        $('#' + payload.id).attr('src', URL.createObjectURL(payload.file));
      }
      reader.readAsDataURL(image);
    },
    onNewVideoSelected(payload) {
      this.tempVideoBlob = URL.createObjectURL(payload.file);
    },
    onNewAudioSelected(payload) {
      this.tempAudioBlob = URL.createObjectURL(payload.file);
    },

    openAddProductImageModal() {
      this.currentContent = null;
      this.$modal.show('add-product-image-modal');
    },
    openAddProductVideoModal() {
      this.currentContent = null;
      this.$modal.show('add-product-video-modal');
    },
    openAddProductAudioModal() {
      this.currentContent = null;
      this.$modal.show('add-product-audio-modal');
    },
    openAddProductTextModal() {
      this.currentContent = null;
      this.$modal.show('add-product-text-modal');
    },
    // setSampleImage(){
    //   // TODO: change sample image on top right of the corner
    // }
    addContent(type, modalName, isSelected = 0) {
      if(this.currentContent == null) {
        this.sweetAlertWarning(this.$t('createOriginalSample.please_select_file_or_finished_record_before_saving'))
        return;
      }
      if (isSelected) {
        let temp = this.productContents;
        for (let i = 0; i < temp.length; i++) {
          if (temp[i].is_selected) {
            temp.splice(i, 1)
            this.setProductContents(temp);
            break;
          }
        }
      }
      this.$modal.hide(modalName);
      let tempContent = {
        id: uuidv4(),
        content_type_id: type,
        data: this.currentContent,
        is_selected: isSelected,
        user: {
          name: this.authUser.name,
          avatar_file: this.authUser.avatar,
        }
      };
      if (Number(type) === 1) {
        tempContent['content'] = this.currentContent;
      } else if (Number(type) === 2) {
        tempContent['file_link'] = window.URL.createObjectURL(this.currentContent);
      } else if (Number(type) === 3) {
        tempContent['file_link'] = window.URL.createObjectURL(this.currentContent);
      } else if (Number(type) === 4) {
        tempContent['file_link'] = window.URL.createObjectURL(this.currentContent);
      }
      this.appendProductContents(tempContent);
    },
    async blobToBase64(blob)  {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
    productContentByType(type) {
      let results = [];
      for (let i = 0; i < this.productContents.length; i++) {
        let currentContent = this.productContents[i];
        if (Number(currentContent.content_type_id) === Number(type)) {
          results.push(currentContent);
        }
      }
      return results;
    },
    onDeleteContent(id) {
      let self = this;
      this.$swal.fire({
        title: this.$t('general.are_you_sure'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('general.yes_delete_it'),
        cancelButtonText: this.$t('general.no_cancel_it'),
        confirmButtonClass: 'btn btn-success',
        cancelButtonClass: 'btn btn-danger',
        buttonsStyling: false
      }).then(function (result) {
        if (result.isConfirmed) {
          self.$swal.fire({
            title: self.$t('general.deleted'),
            icon: 'success'
          })
          self.deleteProductContentById(id);
        }
      })
    },
    submitSample(redirect = false) {
      if(!this.isUserGranted('Sample', ['update', 'create'], false)) {
        setTimeout(() => {
          self.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
        }, 500);
        return false;
      }

      let self = this;
      this.$store.dispatch(CREATE_ORIGINAL_SAMPLE, {user: this.authUser}).then((response) => {
        if (response.status) {
          if(redirect){
            self.$router.push({name: 'sample.product.edit', params: {id: response.data.id}})
            return;
          }
          self.sweetAlertSuccess('general.successfully_created').then(() => {
            self.$router.push({name: 'sample'})
          })
        }
      });
    },

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.sample'), route: "sample"},
      {title: this.$t('general.original_sample')}
    ]);

    if(!this.isUserGranted('Sample', ['create'])) {
      return false;
    }

    this.$store.dispatch(GET_REQUIRED_DATA_FOR_ORIGINAL_SAMPLE, {});
  }
}
</script>

<style scoped>
.company-option-preview-image {
  width: 25px;
  border-radius: 100%;
  height: 25px;
  object-fit: cover;
  display: inline-block
}

.card-body-scroll {
  max-height: 45vh;
  overflow-y: auto;
}


.card{
  border-radius: 35px !important;
  /* background-color: rgba(255, 255, 255, 0.5); */
  padding: 10px;
}

</style>
<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/samples/layout/createOriginalSample.scss";
/*Mobile End*/
</style>
