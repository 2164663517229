var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p-0 m-0"},[(_vm.isInline)?_c('div',{staticClass:"mb-3",class:{'form-inline d-flex': _vm.isInline}},[_c('label',{staticClass:"d-inline-block col-form-label font-size-lg",class:{
        'col-form-label-sm': _vm.size.toString() === 'sm',
        'col-form-label-lg': _vm.size.toString() === 'lg',
      },staticStyle:{"width":"auto !important"},attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group d-inline-block",class:{'mx-3': _vm.isInline},staticStyle:{"flex-grow":"1"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelProxy),expression:"modelProxy"}],staticClass:"form-control w-100",class:{
                  'is-valid': _vm.isValid === true,
                  'is-invalid': _vm.isValid === false,
                  'form-control-sm': _vm.size.toString() === 'sm',
                  'form-control-lg': _vm.size.toString() === 'lg',
                 },attrs:{"id":_vm.name,"rows":_vm.rows,"name":_vm.name,"required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.modelProxy)},on:{"input":function($event){if($event.target.composing)return;_vm.modelProxy=$event.target.value}}}),_c('span',{staticClass:"form-text text-muted",class:{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': _vm.isInline}},[_vm._v(_vm._s(_vm.hint))]),(_vm.error)?_c('p',{staticClass:"text-danger pt-1 px-3 block",class:{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': _vm.isInline}},[_vm._v(_vm._s(_vm.error))]):_vm._e()]),(_vm.helperText)?_c('info-tooltip',{attrs:{"helper-text":_vm.helperText}}):_vm._e()],1):_vm._e(),(!_vm.isInline)?_c('div',{staticClass:"form-group",class:{'row': _vm.isInline}},[_c('label',{staticClass:"col-form-label font-size-lg",class:{
    'col-lg-4 col-md-4 col-sm-12': _vm.isInline,
    'col-form-label-sm': _vm.size.toString() === 'sm',
    'col-form-label-lg': _vm.size.toString() === 'lg',
  },attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.title)),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.helperText)?_c('info-tooltip',{attrs:{"helper-text":_vm.helperText}}):_vm._e()],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelProxy),expression:"modelProxy"}],staticClass:"form-control",class:{'col-lg-8 col-md-8 col-sm-12': _vm.isInline,
                  'is-valid': _vm.isValid == null ? false : _vm.isValid,
                  'is-invalid': _vm.isValid == null ? false : !_vm.isValid,
                  'form-control-sm': _vm.size.toString() === 'sm',
                  'form-control-lg': _vm.size.toString() === 'lg',
                 },attrs:{"id":_vm.name,"rows":_vm.rows,"name":_vm.name,"required":_vm.required,"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.modelProxy)},on:{"input":function($event){if($event.target.composing)return;_vm.modelProxy=$event.target.value}}}),_c('span',{staticClass:"form-text text-muted",class:{'offset-lg-4 offset-md-4 offset-sm-0 col-lg-8 col-md-8 col-sm-12': _vm.isInline}},[_vm._v(_vm._s(_vm.hint))]),(_vm.error)?_c('p',{staticClass:"text-danger pt-1 px-3 block",class:{'offset-lg-4 offset-md-4 col-lg-8 col-md-8 col-sm-12': _vm.isInline}},[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }